/* .content {
  display: flex;
  align-items: center;
  justify-content: center;
} */
.slider-wrapper {
  font-size: 40px;
  color: #aaa;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider {
  height: 50px;
  padding-left: 15px;
  overflow: hidden;
}
.slider div {
  color: #fff;
  height: 50px;
  margin-bottom: 50px;
  padding: 2px 15px;
  text-align: center;
  box-sizing: border-box;
}
.slider-text1 {
  background: lightgreen;
  animation: slide 8s linear infinite;
}
.slider-text2 {
  background: skyblue;
}
.slider-text3 {
  background: lightcoral;
}
.slider-text4 {
  background: rgb(128, 240, 216);
}
@keyframes slide {
  0% {
    margin-top: -300px;
  }
  5% {
    margin-top: -200px;
  }
  33% {
    margin-top: -200px;
  }
  38% {
    margin-top: -100px;
  }
  66% {
    margin-top: -100px;
  }
  71% {
    margin-top: 0px;
  }
  100% {
    margin-top: 0px;
  }
}

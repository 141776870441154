@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@media only screen and (max-width: 2000px) {
.App {
  width: 100%;
  margin: 2rem auto;
  height: 100%;
}

.slide img {
  width: 30rem;
  margin: 0 auto;
  box-shadow: 1px 1px 5px silver;
}

.slide {
  transform: scale(0.2);
  transition: transform 300ms;
  opacity: 0.5;
}

.activeSlide {
  transform: scale(0.9);
  opacity: 0.9;
}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 9;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #ea1d63;
}

.next {
  right: 10%;
  top: 10%;
}

.prev {
  left: 10%;
  top: 10%;
}
}

@media screen and (max-width: 960px) {
  .slide img {
    width: 20rem;
    margin: 0 auto;
  }
  .activeSlide {
    transform: scale(1.2);
    opacity: 0.9;
  }
}

/*Mobile View*/
@media screen and (max-width: 506px) {
  .App {
    width: 100%;
    margin: 1rem auto;
    height: 100%;
    display: none;
  }
  .slide img {
   display: none;
  }
  .slide {
    transform: scale(0.3);
    padding-top: 80px;
    transition: transform 200ms;
    opacity: 0.5;
  }
  .activeSlide {
    padding-top: 30px;
    transform: scale(1.5);
    opacity: 0.9;
  }
  .next {
    right: 10%;
    top: 10%;
  }

  .prev {
    left: 10%;
    top: 10%;
  }
  .arrow svg {
  display: none;
  }
}

/* *{
border-style: solid;
color: red;
} */

@media only screen and (max-width: 1980px) {
.mainContainer {
  width: 97%;
  height: 100%;
  margin-left: 20px;
}
.h1 {
  size: 100px;
  margin-left: 250px;
  margin-top: 50px;
  margin-bottom: 25px;
}
.imgCareer {
  size: 140px;
  height: 140px;
  margin-left: 60px;
  padding-top: 10px;
}
.title {
  size: 50px;
  margin-left: 450px;
  margin-top: 150px;
  margin-bottom: 25px;
}
.Logos {
  width: 15%;
}
.imgLogo {
  width: 15%;
  float: left;
}

.companyDetails {
  margin-left: 240px;
  width: 400px;
}
.role {
  margin-left: 150px;
  margin-bottom: 10px;
  font-size: 30px;
  width: 800px;
  text-decoration: underline;
}
.companyInformation {
  width: 350px;
  margin-left: 75px;
}

.technicalSkills {
  width: 400px;
  margin-left: 150px;
  margin-top: 20px;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}
.ListActivities {
  margin-left: 150px;
  margin-top: 5px;
  margin-right: 40px;
}
.lastItem {
  margin-bottom: 50px;
}
.mainTechnologies {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 150px;
  font-weight: bold;
  color: slategrey;
  font-size: 25px;
}
.sticky {
  position: sticky;
  top: 0;
  padding-top: 0px;
}
.certificates {
  margin-left: 390px;
  margin-bottom: 10px;
  font-size: 30px;
}
.achivements{
  margin-left: 390px;
  margin-top: 20px;
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: bold;
}
.tabs{
  position: fixed;
  width: 100%;
}
}

@media only screen and (max-width: 1600px) {
  .mainContainer {
    width: 98%;
    height: 100%;
    margin-left: 20px;
  }
  .h1 {
    size: 100px;
    margin-left: 250px;
    margin-top: 50px;
    margin-bottom: 25px;
  }
  .imgCareer {
    size: 140px;
    height: 140px;
    margin-left: 60px;
    padding-top: 10px;
  }
  .title {
    size: 50px;
    margin-left: 450px;
    margin-top: 150px;
    margin-bottom: 25px;
  }
  .Logos {
    width: 15%;
  }
  .imgLogo {
    width: 15%;
    float: left;
  }
  
  .companyDetails {
    margin-left: 240px;
    width: 400px;
  }
  .role {
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 30px;
    width: 400px;
  }
  .companyInformation {
    width: 400px;
    margin-left: 5px;
  }
  
  .technicalSkills {
    width: 400px;
    margin-left: 10px;
    margin-top: 20px;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .ListActivities {
    margin-left: 10px;
    margin-top: 5px;
    margin-right: 40px;
    width: 470px;
  }
  .lastItem {
    margin-bottom: 50px;
  }
  .mainTechnologies {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 0px;
    font-weight: bold;
    color: slategrey;
    font-size: 25px;
  }
  .sticky {
    position: sticky;
    top: 0;
    padding-top: 0px;
  }
  .certificates {
    margin-left: 250px;
    margin-bottom: 10px;
    font-size: 30px;
  }
  .achivements{
    margin-left: 250px;
    margin-top: 20px;
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .tabs{
    position: fixed;
    width: 100%;
  }
  }

/*Responsive*/
 @media only screen and (max-width: 506px) {
  .mainContainer {
    width: 98%;
    height: 100%;
  }
  .h1 {
    size: 100px;
    margin-left: 250px;
    margin-top: 50px;
    margin-bottom: 25px;
  }
  .imgCareer {
    width: 80px;
    height: 130px;
    margin-left: 0px;
    padding-top: 50px;
  }
  .title {
    size: 20px;
    margin-left: 450px;
    margin-top: 150px;
    margin-bottom: 25px;
  }
  .Logos {
    width: 15%;
  }
  .imgLogo {
    width: 15%;
    float: left;
  }
  
  .companyDetails {
    width: 200px;
    padding-right: 10px;
    margin-left: 60px;
  }
  .role {
    margin-left: 37px;
    margin-bottom: 10px;
    font-size: 15px;
    padding-top: 50px;
    width: 250px;
  }
  .companyInformation {
    width: 250px;
    margin-left: 18px;
    font-size: 10px;
    padding-right: 10px;
  }
  
  .technicalSkills {
    width: 250px;
    margin-left: 35px;
    margin-top: 10px;
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .ListActivities {
    margin-left: 35px;
    margin-top: 5px;
    width: 250px;
    font-size: 10px;
    padding-right: 20px;
  }
  .lastItem {
    margin-bottom: 50px;
  }
  .mainTechnologies {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 45px;
    font-weight: bold;
    color: slategrey;
    font-size: 12px;
  }
  .sticky {
    position: sticky;
    top: 0;
    padding-top: 0px;
  }
  .certificates {
 display: none;
  }
  .achivements{
   display: none;
  }
  .tabs{
    position: fixed;
    width: 100%;
  }
} 



/*Responsive Horizontal*/
 @media only screen and (max-width: 651px) and (max-height:391px) {
  .mainContainer {
    width: 98%;
    height: 100%;
  }
  .h1 {
    size: 100px;
    margin-left: 250px;
    margin-top: 50px;
    margin-bottom: 25px;
  }
  .imgCareer {
    size: 100px;
    height: 100px;
    margin-left: 0px;
    padding-top: 50px;
  }
  .title {
    size: 20px;
    margin-left: 450px;
    margin-top: 150px;
    margin-bottom: 25px;
  }
  .Logos {
    width: 15%;
  }
  .imgLogo {
    width: 15%;
    float: left;
  }
  
  .companyDetails {
    margin-left: 15%;
    padding-right: 10px;
  }
  .role {
    margin-left: 30px;
    margin-bottom: 10px;
    font-size: 15px;
    padding-top: 50px;
    width: 200px;
  }
  .companyInformation {
    width: auto;
    margin-left: 15px;
    font-size: 10px;
    padding-right: 10px;
  }
  
  .technicalSkills {
    width: auto;
    margin-left: 30px;
    margin-top: 10px;
    font-size: 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .ListActivities {
    margin-left: 30px;
    margin-top: 5px;
    width: auto;
    font-size: 10px;
    padding-right: 20px;
  }
  .lastItem {
    margin-bottom: 50px;
  }
  .mainTechnologies {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
    font-weight: bold;
    color: slategrey;
    font-size: 12px;
  }
  .sticky {
    position: sticky;
    top: 0;
    padding-top: 0px;
  }
  .certificates {
    display: none;
  }
  .achivements{
   display: none;
  }
  .tabs{
    position: fixed;
    width: 100%;
  }
} 



/*Iphone X*/
@media only screen and (max-width: 375px) and (max-height:812px) {
  .imgCareer {
    size: 130px;
    height: 120px;
    padding-right: 15px;
  }
  .role {
    margin-left: 30px;
    margin-bottom: 10px;
    font-size: 15px;
    padding-top: 50px;
    width: 170px;
  }
  .tabs{
    position: fixed;
    width: 98%;
  }
  .companyInformation {
    width: auto;
    margin-left: 15px;
    font-size: 10px;
    padding-right: 10px;
  }
  
  .technicalSkills {
    width: auto;
    margin-left: 30px;
    margin-top: 10px;
    font-size: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    width: 170px;
  }
  .ListActivities {
    margin-top: 5px;
    width: auto;
    font-size: 10px;
    padding-right: 0px;
    width: 190px;

  }
  .lastItem {
    margin-bottom: 50px;
    width: 200px;
  }
  .mainTechnologies {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
    font-weight: bold;
    color: slategrey;
    font-size: 12px;
  }
  .sticky {
    position: sticky;
    top: 0;
    padding-top: 0px;
  }
  .tabs{
    position: fixed;
    width: 100%;
  }
}

/*Horizontal Iphone X*/
@media only screen and (max-width: 812px) and (max-height:375) {
  .imgCareer {
    size: 100px;
    height: 100px;
    margin-left: 20px;
  }
  .tabs{
    position: fixed;
    width: 100%;
  }
}



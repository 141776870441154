/* *{
border-style: solid;
color: red;
} */
@media screen and (max-width: 2000px) {
  .line{
    width: 100%;
    height: 170px;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
  }
.videoLife{
  width: 50%;
}
.containerLife{
  width: 100%;
  height: 535px;
  
}
.SubTileRight {
  padding-right: 220px;
  padding-top: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 2.6rem;
  float: right;
  top: 10px;
  font-weight: bold;
  text-shadow: 2px 2px silver;
}
.PhraseRight{
  width: 100%;
  height: 57%;
  position: relative;
  padding-left: 55%;
  top: -400px;
  padding-right: 150px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
}

/*Education*/
.containerEducation{
  width: 100%;
  height: 535px;
}
.videoEducation{
  margin-top: 0px;
  width: 50%;
  float: right;
}
.SubTileLeft {
  padding-top: 110px;
  font-size: 2.6rem;
  float: right;
  padding-right: 32%;
  font-weight: bold;
  text-shadow: 2px 2px silver;
}
.PhraseLeft{
  padding-right: 5%;
  width: 50%;
  height: 530px;
  position: relative;
  padding-left: 5.5%;
  top: 40px;
  font-size: 25px;
}

/*Skills*/
.containerSkill{
  width: 100%;
  height: 535px;
}

.videoSkill{
  width: 50%;
}
.SubTileSkill {
  padding-right: 760px;
  font-size: 2.6rem;
  float: right;
  margin-top: 65px;
  font-weight: bold;
  text-shadow: 2px 2px silver;
}
.PhraseSkill{
  width: 100%;
  height: 50%;
  position: relative;
  padding-left: 55%;
  top: -350px;
  padding-right: 50px;
  font-size: 25px;
  
}

/*Hobiie*/
.containerHobbie{
  width: 100%;
  height: 535px;
}
.videoHobiie{
  width: 50%;
  float: right;
}
.SubTileHobbie {
  padding-top: 100px;
  font-size: 2.6rem;
  float: right;
  padding-right: 36%;
  font-weight: bold;
  text-shadow: 2px 2px silver;
}
.PhraseHobbie{
  float: left;
  width: 50%;
  position: relative;
  padding-right: 100px;
  padding-left: 5.5%;
  top: 85px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
}
}

/*Mediun Size netWorkImageAbout*/
@media screen and (max-width: 1600px) {
  .line{
    width: 100%;
    height: 70px;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
  }
  .videoLife{
    width: 50%;
  }
  .containerLife{
    width: 100%;
    height: 425px;
  }
  .SubTileRight {
    padding-right: 300px;
    top: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1.7rem;
    float: right;
    font-weight: bold;
    text-shadow: 2px 2px silver;
  }
  .PhraseRight{
    width: 100%;
    height: 57%;
    position: relative;
    padding-left: 53%;
    top: -280px;
    padding-right: 150px;
    font-size: 17px;
  }
  
  /*Education*/
  .containerEducation{
    width: 100%;
    height: 425px;
  }
  .videoEducation{
    margin-top: 0px;
    width: 50%;
    height: 100%;
    float: right;
  }
  .SubTileLeft {
    padding-top: 50px;
    padding-left: 30px;
    font-size: 1.7rem;
    float: right;
    top: 50px;
    padding-right: 35.5%;
    font-weight: bold;
    text-shadow: 2px 2px silver;
  }
  .PhraseLeft{
    /* border-style: solid;
    color: red; */
    padding-right: 5%;
    width: 50%;
    height: 530px;
    position: relative;
    padding-left: 5.5%;
    top: 40px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
  }
  
  /*Skills*/
  .containerSkill{
    width: 100%;
    height: 425px;
  }
  
  .videoSkill{
    width: 50%;
  }
  .SubTileSkill {
    
    width: 50%;
    padding-right: 20px;
    padding-left: 70px;
    font-size: 1.8rem;
    float: right;
    margin-top: 0x;
    font-weight: bold;
    text-shadow: 2px 2px silver;
  }
  .PhraseSkill{
    width: 100%;
    height: 50%;
    position: relative;
    padding-left: 55%;
    top: -250px;
    padding-right: 50px;
    font-size: 18px;
  }
  
  /*Hobiie*/
  .containerHobbie{
    width: 100%;
    height: 425px;
  }
  .videoHobiie{
    width: 50%;
  }
  .SubTileHobbie {
    padding-top: 80px;
    font-size: 1.6rem;
    float: right;
    padding-right: 39%;
    font-weight: bold;
    text-shadow: 2px 2px silver;
  }
  .PhraseHobbie{
    float: left;
    width: 50%;
    position: relative;
    padding-right: 100px;
    padding-left: 5.5%;
    top: 45px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
  }
  }

/*Mobile*/
@media screen and (max-width: 600px) {

  .line{
    display: none;
  }
  /*Life*/
  .videoLife{
    width: 100%;
  }
  .containerLife{
    width: 100%;
    height: 620px;
  }
  .SubTileRight {
   padding-left: 20px;
   width: 100%;
   height: 30px;
   padding-top: 50px;
   padding-bottom: 30px;
   padding-right: 20px;
   font-size: 21px;
   text-shadow: 2px 2px silver;
  }
  .PhraseRight{
    top: 30px;
    width: 100%;
    height: 100%;
    font-size: 15px;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
  }


  /*Education*/
  .containerEducation{
    width: 100%;
    height: auto;
  }
  .videoEducation{
    margin-top: 0px;
    width: 100%;
    float: right;
  }
  .SubTileLeft {
    width: 100%;
    padding-top: 50px;
    padding-left: 20px;
    font-size: 1.6rem;
    padding-right: 20px;
    font-weight: bold;
    text-shadow: 2px 2px silver;
  }
  .PhraseLeft{
    padding-right: 10px;
    width: 100%;
    height: 450px;
    position: relative;
    padding-left: 20px;
    padding-top: 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
  }

  /*Skills*/
  .containerSkill{
    width: 100%;
    height: 620px;
  }
  
  .videoSkill{
    width: 100%;
    margin-top: 150px;
  }
  .SubTileSkill {
    padding-right: 20px;
    width: 100%;
    padding-left: 20px;
    padding-top: 0px;
    font-size: 30px;  
    font-weight: bold;
    text-shadow: 2px 2px silver;
  }
  .PhraseSkill{
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: 50%;
    margin-top: 370px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 15px;
  }

   /*Hobiie*/
  .containerHobbie{
    width: 100%;
    height: 700px;
  }
  .videoHobiie{
    width: 100%;
    margin-top: 150px;
  }
  .SubTileHobbie {
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    font-size: 1.6rem;
    float: right;
    font-weight: bold;
    text-shadow: 2px 2px silver;
  }
  .PhraseHobbie{
    float: left;
    width: 50%;
    position: relative;
    padding-right: 20px;
    padding-left: 20px;
    top: 20px;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
  }
}
/* *{
border-style: solid;
color: red;
} */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=sawp");

@media screen and (max-width: 2000px) {
.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: left;
  padding-left: 20px;
  font-size: 1.6rem;
  position: sticky;
  top: 0;
  z-index: 999;
}
.navbar-container {
  display: flex;
  justify-content: top;
  align-items: center;
  height: 80px;
  max-width: auto;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.9rem;
  display: flex;
}

.navbar-logo:hover {
  opacity: 0.8;
}
.headerBar{
  font-size: 23px;
  font-weight: bold;
  color: orange;
  padding-top: 2px;
}
.fa-angle-left {
  padding-top: 3px;
  color: orange;
}
.fa-angle-right {
  padding-top: 3px;
  color: orange;
}
.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.5rem;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 30px;
  list-style: none;
  text-align: center;
  width: 80vw;
  justify-content: end;
  margin-right: 2rem;
}
.nav-item {
  height: 80px;
  width: 100%;
}
.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}
.nav-links:hover {
  border-bottom: 6px solid orange;
  transition: all 0.2s ease-out;
  color: orange;
}
.fa-bars {
  color: orange;
}
.nav-links-mobile {
  display: none;
}
.menu-icon {
  display: none;
}
}

@media screen and (max-width: 1600px) {
  
.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 30px;
  list-style: none;
  text-align: center;
  width: 75vw;
  justify-content: end;
  margin-right: 2rem;
}
}

@media screen and (max-width: 1024px) {
  .navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: left;
    padding-left: 20px;
    font-size: 1.5rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .navbar-container {
    display: flex;
    justify-content: top;
    align-items: center;
    height: 80px;
    max-width: auto;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.9rem;
    display: flex;
    width: 270px;
  }
  
  .navbar-logo:hover {
    opacity: 0.8;
  }
  .headerBar{
    font-size: 30px;
    font-weight: bold;
    color: orange;
    padding-top: 2px;
  }
  .fa-angle-left {
    padding-top: 3px;
    color: orange;
  }
  .fa-angle-right {
    padding-top: 3px;
    color: orange;
  }
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.3rem;
  }
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 30px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
  }
  .nav-item {
    height: 80px;
    width: 100%;
  }
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  .nav-links:hover {
    border-bottom: 6px solid orange;
    transition: all 0.2s ease-out;
    color: orange;
  }
  .fa-bars {
    color: orange;
  }
  .nav-links-mobile {
    display: none;
  }
  .menu-icon {
    display: none;
  }
  }

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 110vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
    opacity: 0.8;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    overflow-y: scroll;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    opacity: 0.8;
    color: 330748;
    border-radius: 0;
    text-align: center;
  }

  .navbar-logo {
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.9rem;
    cursor: pointer;
  }

  .fa-times {
    color: orange;
    font-size: 2rem;
  }
  .fa-times:hover {
    color: orange;
    opacity: 0.8;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #ea1d63;
    opacity: 0.8;
    color: #fff;
    transition: 250ms;
  }
  .headerBar{
    font-size: 23px;
    font-weight: bold;
    color: orange;
    padding-top: 0px;
  }
  .fa-angle-left {
    padding-top: 3px;
    color: orange;
  }
  .fa-angle-right {
    padding-top: 3px;
    color: orange;
  }

  .nav-links-socialNetWork {
    display: block;
    text-align: center;
    margin: 2rem auto;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    transition: all 0.3s ease-out;
  }
  .fa-linkedin {
    background: transparent;
    color: white;
    width: 100%;
    display: inline;
  }
  .visible {
    visibility: visible;
  }

  .hidden {
    display: none;
  }
}

  @media screen and (max-width: 600px) {
    .navbar-logo img {
      display: none;
    }
    .navbar-logo {
      position: relative;
      top: -15px;
      transform: translate(0%, 50%);
      font-size: 1.5rem;
    }
    .bar{
      font-size: 25px;
      font-weight: bold;
      color: orange;
      padding-top: 1px;
    }
    .fa-angle-left {
      padding-top: 3px;
      color: orange;
    }
    .fa-angle-right {
      padding-top: 3px;
      color: orange;
    }
    .nav-menu.active {
      background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
      opacity: 0.8;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      overflow-y: scroll;
    }
  
  }

  @media screen and (max-width: 416px) {
    
.navbar-container {
  display: flex;
  justify-content: left;
  align-items: left;
  height: 80px;
  max-width: 248px;
}
.bar{
  font-size: 30px;
  font-weight: bold;
  color: orange;
  padding-top: 1px;
}
.nav-menu.active {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
  opacity: 0.8;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
  overflow-y: scroll;
}

  }

.description-container {
  height: 40vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.typewriter h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.3em; /* Adjust as needed */
  animation: typing 3.5s steps(30, end), blink-caret 0.75s step-end infinite;
  color: #fff;
  opacity: 0.8;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  10% {
    border-color: orange;
  }
}

@media screen and (max-width: 484px) {
  .typewriter > h1 {
    font-size: 10px;
    top:100px;
  }
}

@media screen and (max-width: 960px) {
  .typewriter > h1 {
    font-size: 18px;
    margin-top: 0px;
  }
}

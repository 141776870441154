@media only screen and (max-width: 2000px) {
.footer-container {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  padding-left: 40px;
  font-size: 20px;
}

.footer-link-items h2 {
  margin-bottom: 13px;
  font-size: 20px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  text-decoration: underline;
  font-size: 25px;
  line-height: 1.8;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
  font-size: 20px;
  color: orange;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 30px;
}

.social-media-wrap {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: auto;
  margin: 50px auto 0 auto;
}

.social-icons {
  display: flex;
  margin-left: 0px;
  padding-bottom: 50px;
  justify-content: space-evenly;
}

.social-logo {
  color: #fff;
  justify-self: start;
  padding-bottom: 15px;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}
.website-rights:hover {
  color: #fff;
  margin-bottom: 16px;
  font-size: 15px;
}
.bar{
  font-size: 15px;
  font-weight: bold;
  color: orange;
  padding-top: 2px;
}
.fa-angle-left {
  padding-top: 3px;
  color: orange;
}
.fa-angle-right {
  padding-top: 3px;
  color: orange;
}
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 360px) and (max-width: 375px)  {
  
.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 140px;
  padding-left: 45px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
  font-size: 20px;
  color: orange;
}
}

@media only screen and (max-width: 2000px) {
.ContainerRight {
  margin-left: 50%;
  width: 50%;
  height: 540px;
}
.ContainerLeft {
  width: 50%;
  height: 540px;
  width: auto;
  float: left;
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 400%);
}
.textfield {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 20px;
}
.buttonSubmit {
  margin-bottom: 10px;
  margin-left: 13px;
}
}

@media screen and (max-width: 1600px) {
  .ContainerRight {
    margin-left: 50%;
    width: 50%;
    height: 420px;
    /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 400%); */
  }
  .ContainerLeft {
    width: 50%;
    height: 420px;
    width: auto;
    float: left;
  }
  .buttonSubmit {
    margin-bottom: 5px;
    margin-left: 10px;
    margin-top: 5px;
  }
  .textfield {
    margin-top:5px;
    margin-bottom: 1px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .ContainerLeft {
    width: 100%;
    height: 100%;
    margin-left: 0px; 
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .ContainerRight {
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
  }
  .textfield {
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .buttonSubmit {
    padding-left: 10px;
  }
}

/*Mobile*/
@media screen and (max-width: 600px) {
  .ContainerLeft {
    width: 100%;
    height: 100%;
    margin-left: 0px; 
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .ContainerRight {
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
    height: 780px;
    margin-bottom: 0px;
  }
  .textfield {
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .buttonSubmit {
    padding-left: 10px;
  }
}

@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=sawp);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home,
.about,
.contact,
.carrer,
.sign-up {
  display: -webkit-flex;
  display: flex;
  height: 90vh;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 3rem;
}

.about {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 90px;
  opacity: 0.8;
}

.contact {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.carrer {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

/* *{
border-style: solid;
color: red;
} */

@media screen and (max-width: 2000px) {
.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
  height: 80px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  padding-left: 20px;
  font-size: 1.6rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}
.navbar-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: top;
          justify-content: top;
  -webkit-align-items: center;
          align-items: center;
  height: 80px;
  max-width: auto;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.9rem;
  display: -webkit-flex;
  display: flex;
}

.navbar-logo:hover {
  opacity: 0.8;
}
.headerBar{
  font-size: 23px;
  font-weight: bold;
  color: orange;
  padding-top: 2px;
}
.fa-angle-left {
  padding-top: 3px;
  color: orange;
}
.fa-angle-right {
  padding-top: 3px;
  color: orange;
}
.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.5rem;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 30px;
  list-style: none;
  text-align: center;
  width: 80vw;
  -webkit-justify-content: end;
          justify-content: end;
  margin-right: 2rem;
}
.nav-item {
  height: 80px;
  width: 100%;
}
.nav-links {
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}
.nav-links:hover {
  border-bottom: 6px solid orange;
  transition: all 0.2s ease-out;
  color: orange;
}
.fa-bars {
  color: orange;
}
.nav-links-mobile {
  display: none;
}
.menu-icon {
  display: none;
}
}

@media screen and (max-width: 1600px) {
  
.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 30px;
  list-style: none;
  text-align: center;
  width: 75vw;
  -webkit-justify-content: end;
          justify-content: end;
  margin-right: 2rem;
}
}

@media screen and (max-width: 1024px) {
  .navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
    height: 80px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    padding-left: 20px;
    font-size: 1.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .navbar-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: top;
            justify-content: top;
    -webkit-align-items: center;
            align-items: center;
    height: 80px;
    max-width: auto;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.9rem;
    display: -webkit-flex;
    display: flex;
    width: 270px;
  }
  
  .navbar-logo:hover {
    opacity: 0.8;
  }
  .headerBar{
    font-size: 30px;
    font-weight: bold;
    color: orange;
    padding-top: 2px;
  }
  .fa-angle-left {
    padding-top: 3px;
    color: orange;
  }
  .fa-angle-right {
    padding-top: 3px;
    color: orange;
  }
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.3rem;
  }
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 30px;
    list-style: none;
    text-align: center;
    width: 70vw;
    -webkit-justify-content: end;
            justify-content: end;
    margin-right: 2rem;
  }
  .nav-item {
    height: 80px;
    width: 100%;
  }
  .nav-links {
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  .nav-links:hover {
    border-bottom: 6px solid orange;
    transition: all 0.2s ease-out;
    color: orange;
  }
  .fa-bars {
    color: orange;
  }
  .nav-links-mobile {
    display: none;
  }
  .menu-icon {
    display: none;
  }
  }

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 110vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
    opacity: 0.8;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    overflow-y: scroll;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    opacity: 0.8;
    color: 330748;
    border-radius: 0;
    text-align: center;
  }

  .navbar-logo {
    position: absolute;
    top: 0px;
    left: 0px;
    -webkit-transform: translate(25%, 50%);
            transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.9rem;
    cursor: pointer;
  }

  .fa-times {
    color: orange;
    font-size: 2rem;
  }
  .fa-times:hover {
    color: orange;
    opacity: 0.8;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #ea1d63;
    opacity: 0.8;
    color: #fff;
    transition: 250ms;
  }
  .headerBar{
    font-size: 23px;
    font-weight: bold;
    color: orange;
    padding-top: 0px;
  }
  .fa-angle-left {
    padding-top: 3px;
    color: orange;
  }
  .fa-angle-right {
    padding-top: 3px;
    color: orange;
  }

  .nav-links-socialNetWork {
    display: block;
    text-align: center;
    margin: 2rem auto;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    transition: all 0.3s ease-out;
  }
  .fa-linkedin {
    background: transparent;
    color: white;
    width: 100%;
    display: inline;
  }
  .visible {
    visibility: visible;
  }

  .hidden {
    display: none;
  }
}

  @media screen and (max-width: 600px) {
    .navbar-logo img {
      display: none;
    }
    .navbar-logo {
      position: relative;
      top: -15px;
      -webkit-transform: translate(0%, 50%);
              transform: translate(0%, 50%);
      font-size: 1.5rem;
    }
    .bar{
      font-size: 25px;
      font-weight: bold;
      color: orange;
      padding-top: 1px;
    }
    .fa-angle-left {
      padding-top: 3px;
      color: orange;
    }
    .fa-angle-right {
      padding-top: 3px;
      color: orange;
    }
    .nav-menu.active {
      background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
      opacity: 0.8;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      overflow-y: scroll;
    }
  
  }

  @media screen and (max-width: 416px) {
    
.navbar-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: left;
          align-items: left;
  height: 80px;
  max-width: 248px;
}
.bar{
  font-size: 30px;
  font-weight: bold;
  color: orange;
  padding-top: 1px;
}
.nav-menu.active {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
  opacity: 0.8;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
  overflow-y: scroll;
}

  }

:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: #fff;
  background-color: var(--primary);
  color: #242424;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px 10px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}

/* *{
  border-style: solid;
  color: red;
  } */

@media only screen and (max-width: 2000px) {
.video {
  position: fixed;
  z-index: -1;
} 
.homeImage{
  opacity: 0.7;
  position: fixed;
  z-index: -1;
}    

.hero-container {
  /* background: url("/images/img-home.jpg") center center/cover no-repeat; */
  height: 100vh;
  width: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  opacity:0.9;
  color: #fff;
  font-size: 4.6rem;
  margin-top: -100px;
  text-align: center;
  text-shadow: 2px 3px #711C38;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}
}


@media only screen and (max-width: 1600px) {

.video {
  position: fixed;
  z-index: -1;
} 

.hero-container {
  /* background: url("/images/img-home.jpg") center center/cover no-repeat; */
  height: 100vh;
  width: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 4.6rem;
  text-align: center;
  text-shadow: 2px 3px #711C38;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}
}


/*Responsive*/
@media only screen and (max-width: 416px) {
  .video {
    position: fixed;
    z-index: -1;
  } 
  .hero-container > h1 {
    color: #fff;
    font-size: 3.0rem;
    text-align: center;
    padding-top: 100px;
    text-shadow: 2px 3px #711C38;
  }
}

.description-container {
  height: 40vh;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.typewriter h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.3em; /* Adjust as needed */
  -webkit-animation: typing 3.5s steps(30, end), blink-caret 0.75s step-end infinite;
          animation: typing 3.5s steps(30, end), blink-caret 0.75s step-end infinite;
  color: #fff;
  opacity: 0.8;
}

/* The typing effect */
@-webkit-keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@-webkit-keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  10% {
    border-color: orange;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  10% {
    border-color: orange;
  }
}

@media screen and (max-width: 484px) {
  .typewriter > h1 {
    font-size: 10px;
    top:100px;
  }
}

@media screen and (max-width: 960px) {
  .typewriter > h1 {
    font-size: 18px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 2000px) {
.footer-container {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.footer-subscription {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.footer-link-wrapper {
  display: -webkit-flex;
  display: flex;
}

.footer-link-items {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  padding-left: 40px;
  font-size: 20px;
}

.footer-link-items h2 {
  margin-bottom: 13px;
  font-size: 20px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  text-decoration: underline;
  font-size: 25px;
  line-height: 1.8;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
  font-size: 20px;
  color: orange;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.footer-input:-ms-input-placeholder {
  color: #b1b1b1;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 30px;
}

.social-media-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  width: auto;
  margin: 50px auto 0 auto;
}

.social-icons {
  display: -webkit-flex;
  display: flex;
  margin-left: 0px;
  padding-bottom: 50px;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.social-logo {
  color: #fff;
  justify-self: start;
  padding-bottom: 15px;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}
.website-rights:hover {
  color: #fff;
  margin-bottom: 16px;
  font-size: 15px;
}
.bar{
  font-size: 15px;
  font-weight: bold;
  color: orange;
  padding-top: 2px;
}
.fa-angle-left {
  padding-top: 3px;
  color: orange;
}
.fa-angle-right {
  padding-top: 3px;
  color: orange;
}
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .social-media-wrap {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

@media screen and (max-width: 360px) and (max-width: 375px)  {
  
.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.footer-link-wrapper {
  display: -webkit-flex;
  display: flex;
}

.footer-link-items {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  text-align: left;
  width: 140px;
  padding-left: 45px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
  font-size: 20px;
  color: orange;
}
}

@media only screen and (max-width: 2000px) {

* {
  box-sizing: border-box;
}

:root {
  --heights: 90vh;
  --widths: 100%;
}

.slilder-container {
  height: var(--heights);
  width: var(--widths);
  position: relative;
  margin: auto;
  overflow: hidden;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: var(--heights);
  width: var(--widths);
  position: relative;
}

.slide-image {
  width: 100%;
  height: 120%;
  position: absolute;
  object-fit: cover;
}

.slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: right;
  padding-right: 30px;
  font-weight: bold;
  top: 13%;
  z-index: 9;
  font-size: 50px;
  text-shadow: 2px 3px #711C38;
}

.slide-text {
  top: 2%;
}
}

/*Iphone X*/
@media only screen and (max-width: 375px)  {
  .slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  z-index: 9;
  font-size: 25px;
  text-shadow: 2px 3px #711C38;
  top: 10%;
}
.slide-text {
  top: 2%;
}
}

/*Moto G4*/
@media only screen and (max-width: 416px)  {
  .slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  z-index: 9;
  font-size: 21px;
  text-shadow: 2px 3px #711C38;
  top: 10%;
}
.slide-text {
  top: 2%;
}
}


/*Pixel 2*/
@media only screen and (max-width: 411px)  {
  .slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  z-index: 9;
  font-size: 21px;
  text-shadow: 2px 3px #711C38;
  top: 10%;
}
.slide-text {
  top: 2%;
}
}

/*Iphone 5/SE*/
@media only screen and (max-width: 320px)  {
  .slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  z-index: 9;
  font-size: 20px;
  text-shadow: 2px 3px #711C38;
  top: 13%;
}
.slide-text {
  top: 2%;
}
}

/*Ipad */
@media only screen and (max-width: 768px)  {
  .slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  z-index: 9;
  font-size: 25px;
  text-shadow: 2px 3px #711C38;
  top: 10%;
}
.slide-text {
  top: 2%;
}
}


/*Ipad */
@media only screen and (max-width: 812px)  {
  .slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  z-index: 9;
  font-size: 25px;
  text-shadow: 2px 3px #711C38;
  top: 19%;
}
.slide-text {
  top: 2%;
}
}
.space{
    padding-top: 20px;
    height: 200px;
    width: auto;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
}
/* *{
border-style: solid;
color: red;
} */
@media screen and (max-width: 2000px) {
  .line{
    width: 100%;
    height: 170px;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
  }
.videoLife{
  width: 50%;
}
.containerLife{
  width: 100%;
  height: 535px;
  
}
.SubTileRight {
  padding-right: 220px;
  padding-top: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 2.6rem;
  float: right;
  top: 10px;
  font-weight: bold;
  text-shadow: 2px 2px silver;
}
.PhraseRight{
  width: 100%;
  height: 57%;
  position: relative;
  padding-left: 55%;
  top: -400px;
  padding-right: 150px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
}

/*Education*/
.containerEducation{
  width: 100%;
  height: 535px;
}
.videoEducation{
  margin-top: 0px;
  width: 50%;
  float: right;
}
.SubTileLeft {
  padding-top: 110px;
  font-size: 2.6rem;
  float: right;
  padding-right: 32%;
  font-weight: bold;
  text-shadow: 2px 2px silver;
}
.PhraseLeft{
  padding-right: 5%;
  width: 50%;
  height: 530px;
  position: relative;
  padding-left: 5.5%;
  top: 40px;
  font-size: 25px;
}

/*Skills*/
.containerSkill{
  width: 100%;
  height: 535px;
}

.videoSkill{
  width: 50%;
}
.SubTileSkill {
  padding-right: 760px;
  font-size: 2.6rem;
  float: right;
  margin-top: 65px;
  font-weight: bold;
  text-shadow: 2px 2px silver;
}
.PhraseSkill{
  width: 100%;
  height: 50%;
  position: relative;
  padding-left: 55%;
  top: -350px;
  padding-right: 50px;
  font-size: 25px;
  
}

/*Hobiie*/
.containerHobbie{
  width: 100%;
  height: 535px;
}
.videoHobiie{
  width: 50%;
  float: right;
}
.SubTileHobbie {
  padding-top: 100px;
  font-size: 2.6rem;
  float: right;
  padding-right: 36%;
  font-weight: bold;
  text-shadow: 2px 2px silver;
}
.PhraseHobbie{
  float: left;
  width: 50%;
  position: relative;
  padding-right: 100px;
  padding-left: 5.5%;
  top: 85px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
}
}

/*Mediun Size netWorkImageAbout*/
@media screen and (max-width: 1600px) {
  .line{
    width: 100%;
    height: 70px;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 200%);
  }
  .videoLife{
    width: 50%;
  }
  .containerLife{
    width: 100%;
    height: 425px;
  }
  .SubTileRight {
    padding-right: 300px;
    top: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1.7rem;
    float: right;
    font-weight: bold;
    text-shadow: 2px 2px silver;
  }
  .PhraseRight{
    width: 100%;
    height: 57%;
    position: relative;
    padding-left: 53%;
    top: -280px;
    padding-right: 150px;
    font-size: 17px;
  }
  
  /*Education*/
  .containerEducation{
    width: 100%;
    height: 425px;
  }
  .videoEducation{
    margin-top: 0px;
    width: 50%;
    height: 100%;
    float: right;
  }
  .SubTileLeft {
    padding-top: 50px;
    padding-left: 30px;
    font-size: 1.7rem;
    float: right;
    top: 50px;
    padding-right: 35.5%;
    font-weight: bold;
    text-shadow: 2px 2px silver;
  }
  .PhraseLeft{
    /* border-style: solid;
    color: red; */
    padding-right: 5%;
    width: 50%;
    height: 530px;
    position: relative;
    padding-left: 5.5%;
    top: 40px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
  }
  
  /*Skills*/
  .containerSkill{
    width: 100%;
    height: 425px;
  }
  
  .videoSkill{
    width: 50%;
  }
  .SubTileSkill {
    
    width: 50%;
    padding-right: 20px;
    padding-left: 70px;
    font-size: 1.8rem;
    float: right;
    margin-top: 0x;
    font-weight: bold;
    text-shadow: 2px 2px silver;
  }
  .PhraseSkill{
    width: 100%;
    height: 50%;
    position: relative;
    padding-left: 55%;
    top: -250px;
    padding-right: 50px;
    font-size: 18px;
  }
  
  /*Hobiie*/
  .containerHobbie{
    width: 100%;
    height: 425px;
  }
  .videoHobiie{
    width: 50%;
  }
  .SubTileHobbie {
    padding-top: 80px;
    font-size: 1.6rem;
    float: right;
    padding-right: 39%;
    font-weight: bold;
    text-shadow: 2px 2px silver;
  }
  .PhraseHobbie{
    float: left;
    width: 50%;
    position: relative;
    padding-right: 100px;
    padding-left: 5.5%;
    top: 45px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
  }
  }

/*Mobile*/
@media screen and (max-width: 600px) {

  .line{
    display: none;
  }
  /*Life*/
  .videoLife{
    width: 100%;
  }
  .containerLife{
    width: 100%;
    height: 620px;
  }
  .SubTileRight {
   padding-left: 20px;
   width: 100%;
   height: 30px;
   padding-top: 50px;
   padding-bottom: 30px;
   padding-right: 20px;
   font-size: 21px;
   text-shadow: 2px 2px silver;
  }
  .PhraseRight{
    top: 30px;
    width: 100%;
    height: 100%;
    font-size: 15px;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
  }


  /*Education*/
  .containerEducation{
    width: 100%;
    height: auto;
  }
  .videoEducation{
    margin-top: 0px;
    width: 100%;
    float: right;
  }
  .SubTileLeft {
    width: 100%;
    padding-top: 50px;
    padding-left: 20px;
    font-size: 1.6rem;
    padding-right: 20px;
    font-weight: bold;
    text-shadow: 2px 2px silver;
  }
  .PhraseLeft{
    padding-right: 10px;
    width: 100%;
    height: 450px;
    position: relative;
    padding-left: 20px;
    padding-top: 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
  }

  /*Skills*/
  .containerSkill{
    width: 100%;
    height: 620px;
  }
  
  .videoSkill{
    width: 100%;
    margin-top: 150px;
  }
  .SubTileSkill {
    padding-right: 20px;
    width: 100%;
    padding-left: 20px;
    padding-top: 0px;
    font-size: 30px;  
    font-weight: bold;
    text-shadow: 2px 2px silver;
  }
  .PhraseSkill{
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: 50%;
    margin-top: 370px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 15px;
  }

   /*Hobiie*/
  .containerHobbie{
    width: 100%;
    height: 700px;
  }
  .videoHobiie{
    width: 100%;
    margin-top: 150px;
  }
  .SubTileHobbie {
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    font-size: 1.6rem;
    float: right;
    font-weight: bold;
    text-shadow: 2px 2px silver;
  }
  .PhraseHobbie{
    float: left;
    width: 50%;
    position: relative;
    padding-right: 20px;
    padding-left: 20px;
    top: 20px;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
  }
}
@media only screen and (max-width: 2000px) {
.App {
  width: 100%;
  margin: 2rem auto;
  height: 100%;
}

.slide img {
  width: 30rem;
  margin: 0 auto;
  box-shadow: 1px 1px 5px silver;
}

.slide {
  -webkit-transform: scale(0.2);
          transform: scale(0.2);
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  opacity: 0.5;
}

.activeSlide {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  opacity: 0.9;
}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 9;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #ea1d63;
}

.next {
  right: 10%;
  top: 10%;
}

.prev {
  left: 10%;
  top: 10%;
}
}

@media screen and (max-width: 960px) {
  .slide img {
    width: 20rem;
    margin: 0 auto;
  }
  .activeSlide {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0.9;
  }
}

/*Mobile View*/
@media screen and (max-width: 506px) {
  .App {
    width: 100%;
    margin: 1rem auto;
    height: 100%;
    display: none;
  }
  .slide img {
   display: none;
  }
  .slide {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
    padding-top: 80px;
    transition: -webkit-transform 200ms;
    transition: transform 200ms;
    transition: transform 200ms, -webkit-transform 200ms;
    opacity: 0.5;
  }
  .activeSlide {
    padding-top: 30px;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0.9;
  }
  .next {
    right: 10%;
    top: 10%;
  }

  .prev {
    left: 10%;
    top: 10%;
  }
  .arrow svg {
  display: none;
  }
}

@media only screen and (max-width: 2000px) {
.ContainerRight {
  margin-left: 50%;
  width: 50%;
  height: 540px;
}
.ContainerLeft {
  width: 50%;
  height: 540px;
  width: auto;
  float: left;
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 400%);
}
.textfield {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 20px;
}
.buttonSubmit {
  margin-bottom: 10px;
  margin-left: 13px;
}
}

@media screen and (max-width: 1600px) {
  .ContainerRight {
    margin-left: 50%;
    width: 50%;
    height: 420px;
    /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, #ea1d63 400%); */
  }
  .ContainerLeft {
    width: 50%;
    height: 420px;
    width: auto;
    float: left;
  }
  .buttonSubmit {
    margin-bottom: 5px;
    margin-left: 10px;
    margin-top: 5px;
  }
  .textfield {
    margin-top:5px;
    margin-bottom: 1px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .ContainerLeft {
    width: 100%;
    height: 100%;
    margin-left: 0px; 
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .ContainerRight {
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
  }
  .textfield {
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .buttonSubmit {
    padding-left: 10px;
  }
}

/*Mobile*/
@media screen and (max-width: 600px) {
  .ContainerLeft {
    width: 100%;
    height: 100%;
    margin-left: 0px; 
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .ContainerRight {
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
    height: 780px;
    margin-bottom: 0px;
  }
  .textfield {
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .buttonSubmit {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 1980px) {
.mainContainer {
  width: 100%;
  height: 100%;
  margin-left: 20px;
  margin-top: 50px;
}
.imgCareer {
  size: 150px;
  height: 150px;
  margin-left: 250px;
}
.imgCareerDrummond {
  size: 130px;
  height: 130px;
  padding-left: 40px;
}
.title {
  size: 50px;
  margin-left: 450px;
  margin-top: 150px;
  margin-bottom: 25px;
}
.Logos {
  width: 15%;
}
.imgLogo {
  width: 15%;
  /* background: silver; */
  float: left;
}

.companyDetails {
  margin-left: 15%;
}
.role {
  margin-left: 10%;
  margin-bottom: 10px;
  font-size: 30px;
}

.technicalSkills {
  width: 700px;
  margin-left: 160px;
  margin-top: 20px;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}
.ListActivities {
  margin-left: 160px;
  margin-top: 5px;
  width: 800px;
}
.lastItem {
  margin-bottom: 50px;
}
.mainTechnologies {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 150px;
  font-weight: bold;
  color: slategrey;
}
.subtitile {
  margin-left: 150px;
  margin-top: 20px;
  font-size: 15px;
  margin-bottom: 20px;
  font-weight: bold;
}
.totalHours {
  margin-left: 150px;
  font-size: 15px;
  font-weight: bold;
  width: 400px;
}
}

@media only screen and (max-width: 1600px) {
  .mainContainer {
    width: 100%;
    height: 100%;
    margin-left: 20px;
    margin-top: 50px;
  }
  .imgCareer {
    size: 150px;
    height: 150px;
    margin-left: 250px;
  }
  .imgCareerDrummond {
    size: 130px;
    height: 130px;
    padding-left: 40px;
  }
  .title {
    size: 50px;
    margin-left: 450px;
    margin-top: 150px;
    margin-bottom: 25px;
  }
  .Logos {
    width: 15%;
  }
  .imgLogo {
    width: 15%;
    float: left;
  }
  
  .companyDetails {
    margin-left: 15%;
  }
  .role {
    margin-left: 10%;
    margin-bottom: 10px;
    font-size: 30px;
  }
  .companyInformation {
    width: 700px;
    margin-left: 85px;
  }
  
  .technicalSkills {
    width: 700px;
    margin-left: 160px;
    margin-top: 20px;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .ListActivities {
    margin-left: 160px;
    margin-top: 5px;
    width: 800px;
  }
  .lastItem {
    margin-bottom: 50px;
  }
  .mainTechnologies {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 150px;
    font-weight: bold;
    color: slategrey;
  }
  .subtitile {
    margin-left: 1px;
    margin-top: 20px;
    font-size: 15px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .totalHours {
    margin-left: 10px;
    font-size: 15px;
    font-weight: bold;
  }
  }

@media only screen and (max-width: 506px) {
  .mainContainer {
    width: 100%;
    height: 100%;
    padding-right: 15px;
    margin-top: 0px;
  }
  .subtitile {
    margin-left: 35px;
    margin-top: 10px;
    font-size: 10px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .imgCareerDrummond {
    size: 55px;
    height: 55px;
    padding-left: 0px;
  }
  .totalHours {
    margin-left: 30px;
    font-size: 10px;
    font-weight: bold;
    width: 190px;
  }
}

/*Responsive Horizontal*/
@media only screen and (max-width: 651px) and (max-height:391px) {
  .mainContainer {
    width: 100%;
    height: 100%;
    padding-right: 15px;
    margin-top: 0px;
  }
  .subtitile {
    margin-left: 30px;
    margin-top: 20px;
    font-size: 10px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .imgCareerDrummond {
    size: 55px;
    height: 55px;
    padding-left: 0px;
  }
  .role {
    margin-left: 20px;
    margin-bottom: 10px;
    font-size: 30px;
  }
  .totalHours {
    font-size: 10px;
    font-weight: bold;
    width: 250px;
    padding-left: 30px;
  }
}


@media only screen and (max-width: 812px) {
  .imgCareerDrummond {
    size: 60px;
    height: 60px;
    padding-left: 0px;
    margin-top: 50px;
  }
}

/* .content {
  display: flex;
  align-items: center;
  justify-content: center;
} */
.slider-wrapper {
  font-size: 40px;
  color: #aaa;
  font-weight: bold;
  text-transform: uppercase;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.slider {
  height: 50px;
  padding-left: 15px;
  overflow: hidden;
}
.slider div {
  color: #fff;
  height: 50px;
  margin-bottom: 50px;
  padding: 2px 15px;
  text-align: center;
  box-sizing: border-box;
}
.slider-text1 {
  background: lightgreen;
  -webkit-animation: slide 8s linear infinite;
          animation: slide 8s linear infinite;
}
.slider-text2 {
  background: skyblue;
}
.slider-text3 {
  background: lightcoral;
}
.slider-text4 {
  background: rgb(128, 240, 216);
}
@-webkit-keyframes slide {
  0% {
    margin-top: -300px;
  }
  5% {
    margin-top: -200px;
  }
  33% {
    margin-top: -200px;
  }
  38% {
    margin-top: -100px;
  }
  66% {
    margin-top: -100px;
  }
  71% {
    margin-top: 0px;
  }
  100% {
    margin-top: 0px;
  }
}
@keyframes slide {
  0% {
    margin-top: -300px;
  }
  5% {
    margin-top: -200px;
  }
  33% {
    margin-top: -200px;
  }
  38% {
    margin-top: -100px;
  }
  66% {
    margin-top: -100px;
  }
  71% {
    margin-top: 0px;
  }
  100% {
    margin-top: 0px;
  }
}

/* *{
border-style: solid;
color: red;
} */

@media only screen and (max-width: 1980px) {
.mainContainer {
  width: 97%;
  height: 100%;
  margin-left: 20px;
}
.h1 {
  size: 100px;
  margin-left: 250px;
  margin-top: 50px;
  margin-bottom: 25px;
}
.imgCareer {
  size: 140px;
  height: 140px;
  margin-left: 60px;
  padding-top: 10px;
}
.title {
  size: 50px;
  margin-left: 450px;
  margin-top: 150px;
  margin-bottom: 25px;
}
.Logos {
  width: 15%;
}
.imgLogo {
  width: 15%;
  float: left;
}

.companyDetails {
  margin-left: 240px;
  width: 400px;
}
.role {
  margin-left: 150px;
  margin-bottom: 10px;
  font-size: 30px;
  width: 800px;
  text-decoration: underline;
}
.companyInformation {
  width: 350px;
  margin-left: 75px;
}

.technicalSkills {
  width: 400px;
  margin-left: 150px;
  margin-top: 20px;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}
.ListActivities {
  margin-left: 150px;
  margin-top: 5px;
  margin-right: 40px;
}
.lastItem {
  margin-bottom: 50px;
}
.mainTechnologies {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 150px;
  font-weight: bold;
  color: slategrey;
  font-size: 25px;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding-top: 0px;
}
.certificates {
  margin-left: 390px;
  margin-bottom: 10px;
  font-size: 30px;
}
.achivements{
  margin-left: 390px;
  margin-top: 20px;
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: bold;
}
.tabs{
  position: fixed;
  width: 100%;
}
}

@media only screen and (max-width: 1600px) {
  .mainContainer {
    width: 98%;
    height: 100%;
    margin-left: 20px;
  }
  .h1 {
    size: 100px;
    margin-left: 250px;
    margin-top: 50px;
    margin-bottom: 25px;
  }
  .imgCareer {
    size: 140px;
    height: 140px;
    margin-left: 60px;
    padding-top: 10px;
  }
  .title {
    size: 50px;
    margin-left: 450px;
    margin-top: 150px;
    margin-bottom: 25px;
  }
  .Logos {
    width: 15%;
  }
  .imgLogo {
    width: 15%;
    float: left;
  }
  
  .companyDetails {
    margin-left: 240px;
    width: 400px;
  }
  .role {
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 30px;
    width: 400px;
  }
  .companyInformation {
    width: 400px;
    margin-left: 5px;
  }
  
  .technicalSkills {
    width: 400px;
    margin-left: 10px;
    margin-top: 20px;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .ListActivities {
    margin-left: 10px;
    margin-top: 5px;
    margin-right: 40px;
    width: 470px;
  }
  .lastItem {
    margin-bottom: 50px;
  }
  .mainTechnologies {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 0px;
    font-weight: bold;
    color: slategrey;
    font-size: 25px;
  }
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding-top: 0px;
  }
  .certificates {
    margin-left: 250px;
    margin-bottom: 10px;
    font-size: 30px;
  }
  .achivements{
    margin-left: 250px;
    margin-top: 20px;
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .tabs{
    position: fixed;
    width: 100%;
  }
  }

/*Responsive*/
 @media only screen and (max-width: 506px) {
  .mainContainer {
    width: 98%;
    height: 100%;
  }
  .h1 {
    size: 100px;
    margin-left: 250px;
    margin-top: 50px;
    margin-bottom: 25px;
  }
  .imgCareer {
    width: 80px;
    height: 130px;
    margin-left: 0px;
    padding-top: 50px;
  }
  .title {
    size: 20px;
    margin-left: 450px;
    margin-top: 150px;
    margin-bottom: 25px;
  }
  .Logos {
    width: 15%;
  }
  .imgLogo {
    width: 15%;
    float: left;
  }
  
  .companyDetails {
    width: 200px;
    padding-right: 10px;
    margin-left: 60px;
  }
  .role {
    margin-left: 37px;
    margin-bottom: 10px;
    font-size: 15px;
    padding-top: 50px;
    width: 250px;
  }
  .companyInformation {
    width: 250px;
    margin-left: 18px;
    font-size: 10px;
    padding-right: 10px;
  }
  
  .technicalSkills {
    width: 250px;
    margin-left: 35px;
    margin-top: 10px;
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .ListActivities {
    margin-left: 35px;
    margin-top: 5px;
    width: 250px;
    font-size: 10px;
    padding-right: 20px;
  }
  .lastItem {
    margin-bottom: 50px;
  }
  .mainTechnologies {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 45px;
    font-weight: bold;
    color: slategrey;
    font-size: 12px;
  }
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding-top: 0px;
  }
  .certificates {
 display: none;
  }
  .achivements{
   display: none;
  }
  .tabs{
    position: fixed;
    width: 100%;
  }
} 



/*Responsive Horizontal*/
 @media only screen and (max-width: 651px) and (max-height:391px) {
  .mainContainer {
    width: 98%;
    height: 100%;
  }
  .h1 {
    size: 100px;
    margin-left: 250px;
    margin-top: 50px;
    margin-bottom: 25px;
  }
  .imgCareer {
    size: 100px;
    height: 100px;
    margin-left: 0px;
    padding-top: 50px;
  }
  .title {
    size: 20px;
    margin-left: 450px;
    margin-top: 150px;
    margin-bottom: 25px;
  }
  .Logos {
    width: 15%;
  }
  .imgLogo {
    width: 15%;
    float: left;
  }
  
  .companyDetails {
    margin-left: 15%;
    padding-right: 10px;
  }
  .role {
    margin-left: 30px;
    margin-bottom: 10px;
    font-size: 15px;
    padding-top: 50px;
    width: 200px;
  }
  .companyInformation {
    width: auto;
    margin-left: 15px;
    font-size: 10px;
    padding-right: 10px;
  }
  
  .technicalSkills {
    width: auto;
    margin-left: 30px;
    margin-top: 10px;
    font-size: 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .ListActivities {
    margin-left: 30px;
    margin-top: 5px;
    width: auto;
    font-size: 10px;
    padding-right: 20px;
  }
  .lastItem {
    margin-bottom: 50px;
  }
  .mainTechnologies {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
    font-weight: bold;
    color: slategrey;
    font-size: 12px;
  }
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding-top: 0px;
  }
  .certificates {
    display: none;
  }
  .achivements{
   display: none;
  }
  .tabs{
    position: fixed;
    width: 100%;
  }
} 



/*Iphone X*/
@media only screen and (max-width: 375px) and (max-height:812px) {
  .imgCareer {
    size: 130px;
    height: 120px;
    padding-right: 15px;
  }
  .role {
    margin-left: 30px;
    margin-bottom: 10px;
    font-size: 15px;
    padding-top: 50px;
    width: 170px;
  }
  .tabs{
    position: fixed;
    width: 98%;
  }
  .companyInformation {
    width: auto;
    margin-left: 15px;
    font-size: 10px;
    padding-right: 10px;
  }
  
  .technicalSkills {
    width: auto;
    margin-left: 30px;
    margin-top: 10px;
    font-size: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    width: 170px;
  }
  .ListActivities {
    margin-top: 5px;
    width: auto;
    font-size: 10px;
    padding-right: 0px;
    width: 190px;

  }
  .lastItem {
    margin-bottom: 50px;
    width: 200px;
  }
  .mainTechnologies {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
    font-weight: bold;
    color: slategrey;
    font-size: 12px;
  }
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding-top: 0px;
  }
  .tabs{
    position: fixed;
    width: 100%;
  }
}

/*Horizontal Iphone X*/
@media only screen and (max-width: 812px) and (max-height:375) {
  .imgCareer {
    size: 100px;
    height: 100px;
    margin-left: 20px;
  }
  .tabs{
    position: fixed;
    width: 100%;
  }
}




/* *{
  border-style: solid;
  color: red;
  } */

@media only screen and (max-width: 2000px) {
.video {
  position: fixed;
  z-index: -1;
} 
.homeImage{
  opacity: 0.7;
  position: fixed;
  z-index: -1;
}    

.hero-container {
  /* background: url("/images/img-home.jpg") center center/cover no-repeat; */
  height: 100vh;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  opacity:0.9;
  color: #fff;
  font-size: 4.6rem;
  margin-top: -100px;
  text-align: center;
  text-shadow: 2px 3px #711C38;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}
}


@media only screen and (max-width: 1600px) {

.video {
  position: fixed;
  z-index: -1;
} 

.hero-container {
  /* background: url("/images/img-home.jpg") center center/cover no-repeat; */
  height: 100vh;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 4.6rem;
  text-align: center;
  text-shadow: 2px 3px #711C38;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}
}


/*Responsive*/
@media only screen and (max-width: 416px) {
  .video {
    position: fixed;
    z-index: -1;
  } 
  .hero-container > h1 {
    color: #fff;
    font-size: 3.0rem;
    text-align: center;
    padding-top: 100px;
    text-shadow: 2px 3px #711C38;
  }
}

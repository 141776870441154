@media only screen and (max-width: 2000px) {

* {
  box-sizing: border-box;
}

:root {
  --heights: 90vh;
  --widths: 100%;
}

.slilder-container {
  height: var(--heights);
  width: var(--widths);
  position: relative;
  margin: auto;
  overflow: hidden;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: var(--heights);
  width: var(--widths);
  position: relative;
}

.slide-image {
  width: 100%;
  height: 120%;
  position: absolute;
  object-fit: cover;
}

.slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: right;
  padding-right: 30px;
  font-weight: bold;
  top: 13%;
  z-index: 9;
  font-size: 50px;
  text-shadow: 2px 3px #711C38;
}

.slide-text {
  top: 2%;
}
}

/*Iphone X*/
@media only screen and (max-width: 375px)  {
  .slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  z-index: 9;
  font-size: 25px;
  text-shadow: 2px 3px #711C38;
  top: 10%;
}
.slide-text {
  top: 2%;
}
}

/*Moto G4*/
@media only screen and (max-width: 416px)  {
  .slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  z-index: 9;
  font-size: 21px;
  text-shadow: 2px 3px #711C38;
  top: 10%;
}
.slide-text {
  top: 2%;
}
}


/*Pixel 2*/
@media only screen and (max-width: 411px)  {
  .slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  z-index: 9;
  font-size: 21px;
  text-shadow: 2px 3px #711C38;
  top: 10%;
}
.slide-text {
  top: 2%;
}
}

/*Iphone 5/SE*/
@media only screen and (max-width: 320px)  {
  .slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  z-index: 9;
  font-size: 20px;
  text-shadow: 2px 3px #711C38;
  top: 13%;
}
.slide-text {
  top: 2%;
}
}

/*Ipad */
@media only screen and (max-width: 768px)  {
  .slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  z-index: 9;
  font-size: 25px;
  text-shadow: 2px 3px #711C38;
  top: 10%;
}
.slide-text {
  top: 2%;
}
}


/*Ipad */
@media only screen and (max-width: 812px)  {
  .slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  z-index: 9;
  font-size: 25px;
  text-shadow: 2px 3px #711C38;
  top: 19%;
}
.slide-text {
  top: 2%;
}
}